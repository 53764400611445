import {Camera, CameraResultType} from "@capacitor/camera";


export type CapturedImage = {
    dataUrl?: string
}

export const takePicture = async (): Promise<CapturedImage> => {
    try {
        const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: true,
            resultType: CameraResultType.DataUrl,
        });

        // image.webPath will contain a path that can be set as an image src.
        // You can access the original file using image.path, which can be
        // passed to the Filesystem API to read the raw data of the image,
        // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
        return {
            dataUrl: image.dataUrl,
        }
    } catch (e) {
        throw new Error('No Image captured')
    }
};
