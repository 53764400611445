import { IonSpinner } from "@ionic/react";
import React from "react";
import classes from './EstimateLoading.module.css'
import Checkmark from "../Icons/Checkmark";

const EstimateLoading: React.FC<{
    backgroundUrl?: string,
    status: 'LOADING' | "OK"
}> = ({backgroundUrl, status}) => {

    const getStatus = () => {
        switch (status) {
            case "LOADING":
                return <IonSpinner className={classes.loadingSpinner} name={"dots"}/>
            case "OK":
                return <div className={classes.icon} ><Checkmark size={48}/></div>
        } 
        
    }

    return  <><div className={classes.bg} style={{backgroundImage: `url(${backgroundUrl})`}}></div>
            <div className={classes.bgLoading}>
        <div className={classes.loadingText}><h1>Calculating your price</h1></div>
        {getStatus()}
    </div>
    </>
}

export default EstimateLoading