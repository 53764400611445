import {useMutation, useSuspenseQuery} from "@apollo/client";
import {graphql} from "../../gql";
import {AssetAction} from "../../gql/graphql";
import {
    IonAvatar,
    IonIcon,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    useIonRouter
} from "@ionic/react";
import {API_URL} from "../../Globals";
import {trash} from "ionicons/icons";

export const ItemsQueryDocument = graphql(/* GraphQL */ `
    query allItems {
        items {
            id
            amount
            name
            status
            image
            condition
            status
        }
    }
`)

const ItemDeleteMutationDocument = graphql(`
    mutation deleteItem($id: ID!) {
        deleteItem(id: $id)
    }`)

const getName = (segment: Segmenents) => {
    switch (segment) {
        case "assets":
            return "Assets"
        case "sell":
            return "Sold"
        case "investments":
            return "Invested"
    }
}

type Segmenents = 'assets' | 'investments' | 'sell'

const AssetList: React.FC<{
    segment: Segmenents;
}> = ({ segment}) => {

    const { push } = useIonRouter()

    const { data, refetch } = useSuspenseQuery(ItemsQueryDocument, {
        fetchPolicy: 'network-only',
        queryKey: 'assetlist'
    })
    const [deleteItem] = useMutation(ItemDeleteMutationDocument)

    const items = data?.items.filter((item) => {
        switch (segment) {
            case "assets":
                return item.status === AssetAction.Add
            case "sell":
                return item.status === AssetAction.Sell
            case "investments":
                return item.status === AssetAction.Invest
        }
    })

    const list = items?.map((item) =>
        <IonItemSliding key={item.id}>
            {
                segment === "assets" && (<IonItemOptions side="start" onIonSwipe={() => push(`/sell/${item.id}`)}>
                    <IonItemOption expandable>Sell&nbsp;&nbsp;&nbsp;</IonItemOption>
                </IonItemOptions>)
            }
            <IonItem>
                <IonAvatar className="preview">
                    <img alt="" src={`https://img.cirkular.world/${item.image}`}/>
                </IonAvatar>
                <IonLabel>
                    <h3>{item.name}</h3>
                    <p>Condition: {item.condition}</p>
                </IonLabel>
                <div style={{
                    fontSize: "24px"
                }}>{item.amount}$</div>
            </IonItem>
            <IonItemOptions side="end" onIonSwipe={() => {
                deleteItem({variables: {id: item.id}})
                    .then(() => refetch())
            }}>
                <IonItemOption color="danger" expandable>
                    <IonIcon slot="icon-only"  icon={trash}></IonIcon>
                </IonItemOption>
            </IonItemOptions>
        </IonItemSliding>
    )

    return <>
     <IonItem>
                <IonLabel>
                    <h1>Total {getName(segment)}:</h1>
                </IonLabel>
                <div style={{
                    fontSize: "24px"
                }}>{items.reduce((partialSum, a) => partialSum + a.amount, 0)}$</div>
            </IonItem>
    {list}</>

}

export default AssetList
