import {useState} from "react";
import {
    IonBadge, IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonInput,
    IonPage,
    IonRow,
    useIonRouter
} from "@ionic/react";
import './Signup.css';
import {supabase} from "../../Globals";
import {useForm} from "react-hook-form";


const Signup: React.FC = () => {
    const { push, routeInfo} = useIonRouter()
    const [iCodeState, setiCodeState] = useState(false)

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onTouched",
        reValidateMode: "onChange"
    });

    const signUp = (data: any) => {
        console.log(data)
        supabase.from("invite_codes")
            .select("code")
            .eq("code", data.invite_code)
            .single()
            .then((result) => {
                if (result.data?.code) {
                    console.log(data)
                    supabase.auth.signUp({
                        email: data.email,
                        password: data.pin,
                    }).then(() => push('/login'))
                }
                if (result.error) {
                    setiCodeState(true)
                }
            })
    }

    return (<IonPage>
        <IonContent color={"primary"}>
            <IonGrid>
                <IonRow>
                    <IonCol size={"2"}>
                        <IonImg src = './logo.png' style={{width: '150px', height: '100%'}} />
                    </IonCol>
                    <IonCol>
                        <p style={{lineHeight: "50px", fontSize: "3rem", verticalAlign: "center", textAlign: "center"}}>Cirkular</p>
                    </IonCol>
                </IonRow>
                <form onSubmit={handleSubmit(signUp)}>
                    <IonRow>
                        <IonCol>
                            <IonInput label={"invite-code"}  {...register('invite_code', {
                                required: 'This is a required field',
                            })}></IonInput>
                            { (errors.invite_code || iCodeState) &&  <IonBadge color="danger">Code invalid</IonBadge> }
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonInput
                                label={"Email"}
                                {...register('email', {
                                    required: 'This is a required field',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: 'invalid email address'
                                    }
                                })}/>
                            { errors.email &&  <IonBadge color="danger">Email invalid</IonBadge> }
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonInput label={"pin"} type={"number"} {...register('pin', {
                                required: 'This is a required field',
                                minLength: 6,
                                maxLength: 6
                            })}></IonInput>
                            { errors.pin &&  <IonBadge color="danger">Pin needs to be 6 numbers</IonBadge> }
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton expand="block" type="submit">
                                Register
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </form>
            </IonGrid>

        </IonContent>
    </IonPage>);
};

export default Signup;
