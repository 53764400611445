import {IonButton, IonContent, IonLoading, IonPage, useIonLoading, useIonRouter,} from '@ionic/react';
import './Sell.css';
import {graphql} from "../../gql";
import {useMutation, useSuspenseQuery} from "@apollo/client";
import {AllItemsDocument, AssetAction} from "../../gql/graphql";
import {RouteComponentProps} from "react-router";

const SellItemMutationDocument = graphql( /* GraphQl */`
    mutation sellItem($id: ID!, $action: AssetAction!, $target: String) {
        sellItem(id: $id, action: $action, target: $target)
    }
`)

const GetAssetDocument = graphql(`
    query getAsset($id: ID!) {  
        item(id: $id) {
            id
            amount
        }
    }
`)


interface SellPageProps
    extends RouteComponentProps<{
        id: string;
    }> {}

const Sell: React.FC<SellPageProps> = ({match}) => {
    const { push } = useIonRouter()

    const { data, error } = useSuspenseQuery(GetAssetDocument, {
        variables: {
            id: match.params.id,
        }
    })

    const [sellItem, { loading}] = useMutation(SellItemMutationDocument)

    return <IonPage>
        <IonContent color={"primary"}>
                <div className={"sold-popup"}>
                    <h1>Congratulations!</h1>
                    <p>You just sold your first item on Cirkular</p>
                    <p>Now you need to decide how you want to use your money</p>
                    <p>You can either invest it in opportunities<br/>
                        or cash out instantly</p>
                    <div style={{
                        position: "absolute",
                        bottom: '1em',
                        left: '15px',
                        right: '15px',

                    }}><IonButton color={"primary"} expand="block" size="large"
                                  onClick={() => push(`/invest/${match.params.id}`)}>Invest
                        ({(data.item.amount * 1.1).toLocaleString(undefined, {maximumFractionDigits: 2})}$)</IonButton>
                        <IonButton color={"primary"} expand="block" fill="outline" size="large" onClick={() => {
                            sellItem({
                                variables: {
                                    id: match.params.id,
                                    action: AssetAction.Sell,
                                    target: undefined
                                },
                                refetchQueries: [AllItemsDocument, 'assetlist']
                            }).then(() => push('/assets'))

                        }}>
                            Cash Out ({data.item.amount.toLocaleString(undefined, {maximumFractionDigits: 2})}$)
                        </IonButton></div>
                </div>

        </IonContent>
    </IonPage>
};

export default Sell;
