import React from "react";
import './Splashscreen.css'
import {IonContent, IonPage} from "@ionic/react";

export const Splashscreen : React.FC = () => {

    return <IonPage>
        <IonContent fullscreen>
            <div className={'splash-bg'}>
                <div className="filler"></div>
                <img className={'splash-logo'} src={'logo.png'} alt={'logo'}/>
                <div className="break"></div>
                <div>
                    <h1>CIRKULAR</h1>
                    <h3>Sell.Impact.Repeat.</h3>
                </div>
                <div className="break"></div>
                <h2>Entering a new economy ....<br/> </h2>
                <div className="filler"></div>
                <div className="filler"></div>
            </div>

        </IonContent>
    </IonPage>
}
