import React from "react";
import classes from './Estimate.module.css'
import { IonButton, IonCol, IonGrid, IonImg, IonRow } from "@ionic/react";
import { AssetEstimate } from "../../gql/graphql";
import { Link } from "react-router-dom";


const Estimate: React.FC<{
    backgroundUrl?: string,
    estimate: AssetEstimate,
    onSell?: () => void,
    onAdd?: () => void,
    onReject?: () => void,
}> = ({ backgroundUrl, estimate, onSell, onAdd, onReject }) => {
    const padding = 1.75

    return <>
        <div className={classes.bg} style={{ backgroundImage: `url(${backgroundUrl})` }} />
        <div className={classes.estimate}>
            <IonGrid>
                <IonRow>
                    <IonCol size="auto" style={{ paddingLeft: "1rem" }}>
                        <IonImg src={"/item.svg"} style={{ width: "75px" }} />
                    </IonCol>
                    <IonCol style={{paddingLeft: "1rem", paddingTop: "0.5rem"}}>
                        <strong className={classes.wrapText} style={{ fontSize: estimate.name.length > 8 ? '1em' : '2em' }}>{estimate.name}</strong>
                        <br />In {estimate.condition} condition</IonCol>
                </IonRow>
                <IonRow style={{ textAlign: "center", verticalAlign: "center", marginTop: "20px", fontSize: "5vh" }}>
                    <IonCol>
                        <strong>Sell instantly for</strong>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol style={{ textAlign: "center", verticalAlign: "center", paddingTop: "0.5rem", fontSize: "7vh" }}>
                        <strong>{estimate.estimate}$</strong>
                    </IonCol>
                </IonRow>

                <div style={{
                    textAlign: "center",
                    verticalAlign: "center",
                    paddingTop: `${padding}em`
                }}>
                    <IonButton expand={"block"} onClick={onSell}>Sell</IonButton>

                </div>

                <div style={{
                    textAlign: "center", verticalAlign: "center",
                    paddingTop: `${padding}em`
                }}>
                    <IonButton style={{
                        minWidth: '60%'
                    }} color="secondary" onClick={onAdd}>Add to inventory</IonButton>
                </div>

                <div style={{
                    textAlign: "center", verticalAlign: "center",
                    paddingTop: `${padding-1}em`,
                    paddingBottom: "0.5em"
                }}>

                    <IonButton fill="clear" onClick={onReject}>Reject</IonButton>
                </div>
            </IonGrid>
        </div></>
}

export default Estimate;