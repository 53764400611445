import {IonButton, IonContent, IonLoading, IonPage, useIonLoading, useIonRouter,} from '@ionic/react';
import classes from './Reject.module.css'
import {useMutation} from "@apollo/client";
import {AddItemToAssetsDocument} from "../assessment/Assessment";
import {RouteComponentProps} from "react-router";

interface RejectProps
    extends RouteComponentProps<{
        id: string;
    }> {}

export const Reject: React.FC<RejectProps> = ({match}) => {
    const { push } = useIonRouter()
    const [addItemToAssets, {loading: addingToAsset}] = useMutation(AddItemToAssetsDocument)

    return <IonPage>
        <IonContent color={"primary"}>
            <div className={classes.container}>
            <div className={classes.soldPopup}>
                <h2 style={{color: 'black !important'}}>We are sorry to hear the price did not meet your expectations</h2>
                <h3 style={{color: 'black'}}>Do you still want to add the item to your assets?</h3>
                <div style={{
                  paddingTop: '1.5em'

                }}><IonButton color={"primary"} expand="block" size="large"
                              onClick={() => addItemToAssets({variables: {id: match.params.id}, refetchQueries:['assetlist']} )
                                  .then(() => push(`/assets/`))}>Yes</IonButton>
                    <IonButton color={"primary"} expand="block" fill="outline" size="large" onClick={() => push('/splash')}>Sell another item</IonButton>
                </div>
            </div>
            </div>
        </IonContent>
    </IonPage>
};
