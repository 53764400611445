import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import './Assassment.css';
import {useCallback, useContext, useEffect, useState} from "react";
import {AppContext} from "../../components/Context/AppContextProvider";
import {graphql} from "../../gql";
import {useMutation, useQuery} from "@apollo/client";
import EstimateLoading from '../../components/EstimateLoading/EstimateLoading';
import Estimate from '../../components/Estimate/Estimate';
import { RouteComponentProps } from 'react-router';


export const AddItemToAssetsDocument = graphql(`
    mutation AddItemToAsset($id: ID!) {
        acceptEstimate(id: $id, action: ADD) {
            id
        }
    }
`)

export const GetEstimate = graphql(`
    query GetEstimate($id: ID!) {
        estimate(id: $id) {
            id
            name
            estimate
            image
            condition
        }
    }
`)

interface AssessmentPageProps
    extends RouteComponentProps<{
        id: string;
    }> {}


const Assessment: React.FC<AssessmentPageProps> = ({match}) => {
    const {appState} = useContext(AppContext)
    const [isLoading, setIsLoading] = useState(true)

    const {data, loading} = useQuery(GetEstimate, {variables: {id: match.params.id}})
    
    const [addItemToAssets, {loading: addingToAsset}] = useMutation(AddItemToAssetsDocument)
    const {push, routeInfo} = useIonRouter()

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsLoading(false)
          }, 1500);
      
          // Cleanup function to clear the timeout if the component unmounts
          return () => clearTimeout(timeoutId);

    }, [])

    const addToAssets = useCallback(() => addItemToAssets({variables: {id: match.params.id}, refetchQueries: ['assetlist']}), [addItemToAssets])

    const handleSell = () => {
        addToAssets()
            .then(({data}) => push(`/sell/${data?.acceptEstimate.id}`))
    }
    const handleAdd = () => {
        addToAssets()
            .then(({data}) => push(`/assets`))
    }
    const handleReject = () => {
        push(`/reject/${match.params.id}`)
    }

    if (isLoading || loading) {
        return <IonPage>
            <IonContent fullscreen>
            <EstimateLoading backgroundUrl={appState.backgroundUrl} status='OK' />
                
            </IonContent>
        </IonPage>
    }
    return <IonPage>
        <IonContent fullscreen>
            <Estimate backgroundUrl={appState.backgroundUrl} estimate={data!.estimate} onSell={handleSell} onAdd={handleAdd} onReject={handleReject}/>
        </IonContent>
    </IonPage>

};

export default Assessment;
