import {Redirect, Route} from 'react-router-dom';
import {IonApp, IonLoading, IonRouterOutlet, setupIonicReact} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import Assets from './pages/assets/Assets';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */
/* import '@ionic/react/css/palettes/dark.always.css'; */
/* import '@ionic/react/css/palettes/dark.class.css'; */
// import '@ionic/react/css/palettes/dark.system.css';
/* Theme variables */
import './theme/variables.css';
import Login from "./pages/login/Login";
import Splash from "./pages/spash/Splash";
import Assessment from "./pages/assessment/Assessment";
import {AppContextProvider} from "./components/Context/AppContextProvider";
import Refine from "./pages/refine/Refine";
import Sell from "./pages/Sell/Sell";
import Signup from "./pages/signup/Signup";
import Invest from "./pages/invest/Invest";
import AuthBarrier from "./components/Auth/Auth";
import AuthProvider from "./components/Auth/AuthProvider";
import React, {Suspense} from 'react';
import {Splashscreen} from "./components/Splashscreen/Splashscreen";
import ServerErrorPage from "./components/ServerError/ServerErrorPage";
import {Reject} from "./pages/reject/Reject";
import * as Sentry from "@sentry/react";


setupIonicReact();

const App: React.FC = () => (<IonApp>
    <IonReactRouter>
        <AppContextProvider>
            <AuthProvider>
                <IonRouterOutlet>
                    <Sentry.ErrorBoundary fallback={<ServerErrorPage/>}>
                        <Suspense fallback={<Splashscreen/>}>
                            <AuthBarrier>
                                <Route path="/assessment/:id" component={Assessment}/>
                                <Route exact path="/splash" component={Splash}/>
                                <Route exact path="/assets" component={Assets}/>
                                <Route path="/refine" component={Refine}/>
                                <Suspense fallback={<IonLoading isOpen />}>
                                    <Route path="/sell/:id" component={Sell}/>
                                </Suspense>
                                <Route path="/invest/:id" component={Invest}/>
                                <Route path="/reject/:id" component={Reject}/>
                            </AuthBarrier>
                            <Route exact path="/login">
                                <Login/>
                            </Route>
                            <Route path="/signup">
                                <Signup/>
                            </Route>
                            {/*<Route exact path="/">*/}
                            {/*    <Redirect push to="/assets"/>*/}
                            {/*</Route>*/}
                            <Redirect exact from="/" to="/assets"/>
                        </Suspense>
                    </Sentry.ErrorBoundary>
                </IonRouterOutlet>
            </AuthProvider>
        </AppContextProvider>
    </IonReactRouter>
</IonApp>);

export default App;
