import {IonCol, IonContent, IonGrid, IonPage, IonRow, useIonLoading, useIonRouter,} from '@ionic/react';
import './Invest.css';
import {RouteComponentProps} from "react-router";
import {graphql} from "../../gql";
import {useMutation} from "@apollo/client";
import {AllItemsDocument, AssetAction} from "../../gql/graphql";

interface InvestPageProps
    extends RouteComponentProps<{
        id: string;
    }> {}

const SellItemMutationDocument = graphql( /* GraphQl */`
    mutation sellItem($id: ID!, $action: AssetAction!, $target: String) {
        sellItem(id: $id, action: $action, target: $target)
    }
`)

const Invest: React.FC<InvestPageProps> = ({match}) => {
    const {push} = useIonRouter()

    const [sellItem] = useMutation(SellItemMutationDocument)
    const [present, dismiss] = useIonLoading();

    const invest = async (target: string): Promise<any> => {
        present()
        await sellItem({variables: {
            id: match.params.id,
                target,
                action: AssetAction.Invest
            }, refetchQueries: [AllItemsDocument, 'assetlist'],
            awaitRefetchQueries: true})
        dismiss()
        push('/assets')
    }

    return <IonPage>
        <IonContent color={"primary"}>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <h3>Select your preferred investment category?</h3>
                    </IonCol>
                </IonRow>
                <IonRow style={{borderBottom: '1px solid #ccc'}} onClick={() => invest("etf")}>
                    <IonCol size={"24"}>
                        <h1>Growth ETF</h1>
                    </IonCol>
                    <IonCol>
                        Benefit from current technology trends and invest in the top tech players
                    </IonCol>
                </IonRow>
                <IonRow style={{borderBottom: '1px solid #ccc', backgroundImage: 'url("/esg.png")', backgroundSize: 'cover'}}
                        onClick={() => invest("esg")}>
                    <IonCol size={"24"}>
                        <h1>ESG</h1>
                    </IonCol>
                    <IonCol>
                        Invest in sustainable companies.<br/>
                        Achieve up to 10% p.a.
                    </IonCol>
                </IonRow>
                <IonRow style={{borderBottom: '1px solid #ccc', backgroundImage: 'url("/crypto.png")', backgroundSize: 'cover'}}
                        onClick={() => invest("crypto")}>
                    <IonCol size={"24"}>
                        <h1>Crypto</h1>
                    </IonCol>
                    <IonCol>
                        Diversify into digital assets. Potential high returns.
                    </IonCol>
                </IonRow>
                <IonRow style={{borderBottom: '1px solid #ccc'}} onClick={() => invest("social_business")}>
                    <IonCol size={"24"}>
                        <h1>Social Business</h1>
                    </IonCol>
                    <IonCol>
                        Support micro-entrepreneurs globally.<br/>
                        Make a social impact.
                    </IonCol>
                </IonRow>
                <IonRow style={{}} onClick={() => invest("ngo")}>
                    <IonCol size={"24"}>
                        <h1>Donate to NGOs</h1>
                    </IonCol>
                    <IonCol>
                        Contribute to social projects and make a difference
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    </IonPage>
};

export default Invest;
