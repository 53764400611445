/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n    mutation sellItem($id: ID!, $action: AssetAction!, $target: String) {\n        sellItem(id: $id, action: $action, target: $target)\n    }\n": types.SellItemDocument,
    "\n    query getAsset($id: ID!) {  \n        item(id: $id) {\n            id\n            amount\n        }\n    }\n": types.GetAssetDocument,
    "\n    mutation AddItemToAsset($id: ID!) {\n        acceptEstimate(id: $id, action: ADD) {\n            id\n        }\n    }\n": types.AddItemToAssetDocument,
    "\n    query GetEstimate($id: ID!) {\n        estimate(id: $id) {\n            id\n            name\n            estimate\n            image\n            condition\n        }\n    }\n": types.GetEstimateDocument,
    "\n    query allItems {\n        items {\n            id\n            amount\n            name\n            status\n            image\n            condition\n            status\n        }\n    }\n": types.AllItemsDocument,
    "\n    mutation deleteItem($id: ID!) {\n        deleteItem(id: $id)\n    }": types.DeleteItemDocument,
    "\n    mutation EstimateItem($images: [String!]!) {\n        estimateItem(images: $images) {\n            __typename\n            id\n            name\n            condition\n            estimate\n        }\n    }\n": types.EstimateItemDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation sellItem($id: ID!, $action: AssetAction!, $target: String) {\n        sellItem(id: $id, action: $action, target: $target)\n    }\n"): (typeof documents)["\n    mutation sellItem($id: ID!, $action: AssetAction!, $target: String) {\n        sellItem(id: $id, action: $action, target: $target)\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getAsset($id: ID!) {  \n        item(id: $id) {\n            id\n            amount\n        }\n    }\n"): (typeof documents)["\n    query getAsset($id: ID!) {  \n        item(id: $id) {\n            id\n            amount\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation AddItemToAsset($id: ID!) {\n        acceptEstimate(id: $id, action: ADD) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation AddItemToAsset($id: ID!) {\n        acceptEstimate(id: $id, action: ADD) {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetEstimate($id: ID!) {\n        estimate(id: $id) {\n            id\n            name\n            estimate\n            image\n            condition\n        }\n    }\n"): (typeof documents)["\n    query GetEstimate($id: ID!) {\n        estimate(id: $id) {\n            id\n            name\n            estimate\n            image\n            condition\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query allItems {\n        items {\n            id\n            amount\n            name\n            status\n            image\n            condition\n            status\n        }\n    }\n"): (typeof documents)["\n    query allItems {\n        items {\n            id\n            amount\n            name\n            status\n            image\n            condition\n            status\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation deleteItem($id: ID!) {\n        deleteItem(id: $id)\n    }"): (typeof documents)["\n    mutation deleteItem($id: ID!) {\n        deleteItem(id: $id)\n    }"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation EstimateItem($images: [String!]!) {\n        estimateItem(images: $images) {\n            __typename\n            id\n            name\n            condition\n            estimate\n        }\n    }\n"): (typeof documents)["\n    mutation EstimateItem($images: [String!]!) {\n        estimateItem(images: $images) {\n            __typename\n            id\n            name\n            condition\n            estimate\n        }\n    }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;