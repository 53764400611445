import React from "react";
import {useAuth} from "./AuthProvider";
import Login from "../../pages/login/Login";


const AuthBarrier: React.FC<React.PropsWithChildren> = ({ children }) => {
   const {user} = useAuth()
    if (!user) return <Login/>

    return children
}


export default AuthBarrier
