import React from "react";
import './Icon.css'


const Checkmark: React.FC<{size: number}> = ({size}) => {
    return (
        <div className="animated_check_circle">
            <svg viewBox="0 0 130.2 130.2" width={`${size}px`} height={`${size}px`}>
                <circle
                    className="path circle"
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeMiterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                />
                <polyline
                    className="path check"
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    points="100.2,40.2 51.5,88.8 29.8,67.5"
                />
            </svg>
        </div>
    )
}

export default Checkmark