import React, {createContext, Dispatch, SetStateAction, useReducer, useState} from "react";
import {CapturedImage} from "../../util/CameraUtil";

let AppContext = createContext<{
    appState: {
        images?: CapturedImage[]
        backgroundUrl?: string,
    },
    setAppState: Dispatch<SetStateAction<{
        images?: CapturedImage[]
        backgroundUrl?: string,
    }>>
}>({
    appState: {},
    setAppState: () => {}
    });


function AppContextProvider(props: React.PropsWithChildren) {
    const [appState, setAppState] = useState<{
        images?: CapturedImage[]
        backgroundUrl?: string,
    }>({})

    const value = { appState, setAppState }


    return (
        <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
    );
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
