import { Session, User } from '@supabase/supabase-js';
import React, {createContext, useState, useEffect, useContext, PropsWithChildren} from 'react';
import {supabase} from "../../Globals";
import {Splashscreen} from "../Splashscreen/Splashscreen";

const AuthContext = createContext<{
    user: User | null;
    signOut: null | (() => void);
}>({
    user: null,
    signOut: null
});

export const useAuth = () => {
    return useContext(AuthContext);
};

const AuthProvider = ({ children }: PropsWithChildren) => {
    const [user, setUser] = useState<User | null>(null);
    const [_, setSession] = useState<Session | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const { data: listener } = supabase.auth.onAuthStateChange(
            (_event, session) => {
                setSession(session);
                setUser(session?.user || null);
                setLoading(false);
            }
        );
        return () => {
            listener?.subscription.unsubscribe();
        };
    }, []);

    const signOut = async () => {
        const { error } = await supabase.auth.signOut();
        console.log('error: ', error);
        if (!error) {
            setUser(null);
            setSession(null);
        }
        return { error };
    };

    if (loading) {
        return <Splashscreen/>
    }

    return (
        <AuthContext.Provider value={{ user, signOut }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
