import {useCallback, useEffect, useRef, useState} from "react";
import {
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonInput, IonLabel,
    IonPage,
    IonRow,
    IonText, useIonLoading,
    useIonRouter
} from "@ionic/react";
import './Login.css';
import {supabase} from "../../Globals";
import PinEntry from "../../components/PinEntry/PinEntry";
import {Link} from "react-router-dom";

const Login: React.FC = () => {
    const [email, setEmail] = useState<string>()

    const {push} = useIonRouter()
    const [present, dismiss] = useIonLoading();
    const [error, setError] = useState<boolean>(false)
    const [pinKey, setPinKey] = useState<string>("1")

    const onLogin = useCallback((pin: string) => {
        present({
            spinner: "circles",
            message: "Logging you in"
        })
        supabase.auth.signInWithPassword({email: email + "", password: pin}).then((event) => {
            dismiss()
            if (!event.error) {
                push('/splash');
            } else {
                setPinKey((prevState) => (prevState + 1) +"")
                setError(true)
            }
        })
    }, [push, email, present, dismiss])

    useEffect(() => {
        supabase.auth.getSession()
            .then(({data, error}) => {

            })
    }, []);

    return (<IonPage>
        <IonContent color={"primary"}>
            <IonGrid>
                <IonRow style={{
                    marginBottom: "1rem",
                }}>
                    <IonCol size={"4"}>
                        <IonImg src='./logo.png' style={{width: '8rem', height: '100%'}}/>
                    </IonCol>
                    <IonCol>
                        <p style={{
                            lineHeight: "3rem",
                            fontSize: "3rem",
                            verticalAlign: "center",
                            textAlign: "center"
                        }}>Cirkular</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonInput
                            class={"email"}
                            labelPlacement="floating"
                            onIonInput={(e) => {
                                if (error) {
                                    setError(false)
                                }
                                setEmail(e.target.value + "")
                        }}
                            inputMode={"email"}>
                            <div slot="label">
                                Email <IonText color="danger">(Required)</IonText>
                            </div>
                        </IonInput>
                        {error && <IonLabel color={"danger"}>Username or Pin incorrect</IonLabel>}
                    </IonCol>
                </IonRow>
                <IonRow style={{
                    marginTop: "calc(10vh - 25px)",
                }}> <PinEntry key={pinKey} onSubmit={onLogin} disabled={!((email || "").includes("@"))}/>
                </IonRow>
                <IonRow>
                    <IonCol style={{marginTop: "20px"}}><Link style={{color: "white", textDecoration: "none"}}
                                                              to={'/signup'}>Got an invite code? Signup!</Link></IonCol>`
                </IonRow>
            </IonGrid>
        </IonContent>
    </IonPage>);
};

export default Login;
