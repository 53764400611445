import React from 'react';
import { createRoot } from 'react-dom/client';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import App from './App';
import './main.css';
import {ApolloClient, InMemoryCache, ApolloProvider, gql, createHttpLink} from '@apollo/client';
import {API_URL, supabase} from "./Globals";
import {setContext} from "@apollo/client/link/context";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { Analytics } from "@vercel/analytics/react"
import {DEV} from "@apollo/client/utilities/globals";
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://8e54243b4804daae0b89b97a76ac32d7@o4507708185509888.ingest.de.sentry.io/4507708187476049",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (DEV) {
    // Adds messages only in a dev environment
    loadDevMessages();
    loadErrorMessages();
}

const httpLink = createHttpLink({
    uri: `${API_URL}/graphql`,
})

const authLink = setContext(async (_, { headers }) => {
    const token = (await supabase.auth.getSession()).data.session?.access_token

    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : '',
        },
    }
})

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

defineCustomElements(window);
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
      <ApolloProvider client={client}>
          <App />
          <Analytics />
      </ApolloProvider>
  </React.StrictMode>
);
