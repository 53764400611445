import React, {useRef} from "react";
import {IonCol, IonInput} from "@ionic/react";

const PinEntry: React.FC<{onSubmit: (value: string) => void, disabled?: boolean }> = (
    { disabled, onSubmit }
) => {
    const inputRef1 = useRef<HTMLIonInputElement>(null);
    const inputRef2 = useRef<HTMLIonInputElement>(null);
    const inputRef3 = useRef<HTMLIonInputElement>(null);
    const inputRef4 = useRef<HTMLIonInputElement>(null);
    const inputRef5 = useRef<HTMLIonInputElement>(null);
    const inputRef6 = useRef<HTMLIonInputElement>(null);
    const refs = [inputRef1, inputRef2, inputRef3, inputRef4, inputRef5, inputRef6];

    const checkState = () => {
        const value = refs.map((rev: React.RefObject<HTMLIonInputElement>) => rev.current?.value)
            .map(value => value && value + "" || " ")
            .join('')
        if (value.match("[0-9][0-9][0-9][0-9][0-9][0-9]")) {
            onSubmit?.(value)
        }
    }

    const updateValue = function (index:number, value: string) {
        if (value === '') {
            refs[index - 1]?.current?.setFocus()
        } else {
            refs[index + 1]?.current?.setFocus()
        }
        checkState()
    }

    return <>
        {
            refs.map((inputRef, idx) => (
                <IonCol key={idx}>
                    <IonInput
                        disabled={disabled}
                        name={`pinentry-${idx}`}
                        required
                        color={"secondary"}
                        // class={"custom"}
                        style={{
                            textAlign: "center",
                        }}
                        min={0}
                        max={9}
                        /*// @ts-ignore*/
                        ref={(ref) => inputRef.current = ref}
                        maxlength={1}
                        fill={"solid"}
                        inputMode={"numeric"}
                        onIonInput={(e) => updateValue(idx, e.target.value + "")}
                        onKeyDown={(e) => {
                            if (refs[idx]?.current?.value === '' && (e.key === 'Delete' || e.key === 'Backspace')) {
                                updateValue(idx, '')
                                return false;
                            }
                        }}
                    />
                </IonCol>
            ))
        }</>
}

export default PinEntry
