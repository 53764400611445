import {
    IonButton, IonContent, IonPage, useIonRouter
} from '@ionic/react';
import classes from './Splash.module.css';
import {CapturedImage, takePicture} from "../../util/CameraUtil";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {AppContext} from "../../components/Context/AppContextProvider";
import { useMutation } from '@apollo/client';
import { graphql } from '../../gql';
import { useAuth } from '../../components/Auth/AuthProvider';
import { dataUrlToFile } from '../../util/Utils';
import {API_URL, supabase} from '../../Globals';
import EstimateLoading from '../../components/EstimateLoading/EstimateLoading';

const GetEstimateMutationDocument = graphql(`
    mutation EstimateItem($images: [String!]!) {
        estimateItem(images: $images) {
            __typename
            id
            name
            condition
            estimate
        }
    }
`)

const uploadImage = async (image: CapturedImage) => {
    const token = (await supabase.auth.getSession()).data.session?.access_token
    const file = dataUrlToFile(image.dataUrl!, "image1.jpg")
    const form = new FormData()
    form.append('file', file)

    const response = await fetch(`${API_URL}/images`, {
        method: 'POST',
        body: form,
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
    // const {data } = await supabase.storage.from("uploads").upload(`${userId}/${uuid()}.jpg`, file)
    return await response.text()
}

const Splash: React.FC = () => {
    const {setAppState} = useContext(AppContext)

    const {push} = useIonRouter()
    const [getEstimate, { data, loading, error }] = useMutation(GetEstimateMutationDocument)
    const [capuredImage, setCapturedImage] = useState<CapturedImage>()
    const { user } = useAuth()

    const uploadAndEstimate = useCallback(async (image: CapturedImage) => {
        setCapturedImage(image)
        const  uploadedImagePath =  await uploadImage(image)
        const estimate = await getEstimate({
            variables: { images: [uploadedImagePath] }
        })
        setAppState({backgroundUrl: image.dataUrl})
        push(`/assessment/${estimate.data?.estimateItem.id}`)

    }, [setCapturedImage, getEstimate, push, user])

    if (capuredImage) {
        return <IonPage>
        <IonContent fullscreen>
            <EstimateLoading backgroundUrl={capuredImage.dataUrl} status='LOADING'/>
        </IonContent>
    </IonPage>
    }
    

    return <IonPage>
        <IonContent fullscreen>
            <div className={classes.bgArt} style={{
                backgroundImage: 'url("/sell_splash.png")'
            }}/>
            <div className={classes.buttonContainer}>
                <IonButton expand={"block"} onClick={() => {
                        takePicture()
                            .then(uploadAndEstimate)
                            .catch(e => {throw new Error(e)})
                    }
                }>Let's go</IonButton>
            </div>
        </IonContent>
    </IonPage>
};

export default Splash;
