import './Refine.css';
import {
    IonButton, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonPage, IonRow, IonThumbnail, useIonRouter
} from "@ionic/react";
import React, {useContext, useState} from "react";
import {AppContext} from "../../components/Context/AppContextProvider";
import {addCircleOutline, push} from 'ionicons/icons';
import {takePicture} from "../../util/CameraUtil";


const Splash: React.FC = () => {

    const {appState, setAppState} = useContext(AppContext)
    const {push} = useIonRouter()

    const addImage = () => {
        takePicture()
            .then((i) => setAppState((prevState: any) => ({
                ...prevState,
                images: [...prevState.images!!, i]
            })))
    }

    const disabled = appState.images?.length != undefined && appState.images.length < 1

    return <IonPage>
        <IonContent fullscreen>
            <div style={{
                backgroundSize: "cover",
                backgroundImage: `url(${appState.backgroundUrl})`,
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
            }}></div>
            <div style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                top: "35vh",
                backgroundColor: "rgb(230,230,230)",
                borderTopLeftRadius: "50px",
                borderTopRightRadius: "50px",
                paddingTop: "0.5rem",
                opacity: "90%"
            }}>
                <IonGrid>
                    <IonRow>
                        <p>To refine the estimate, you can upload</p>
                        <p>up to two additional pictures of the item</p>
                    </IonRow>
                    <IonRow>
                    {appState.images?.map((image, idx) => (
                            <IonCol key={idx + ""} size="4">
                                <IonThumbnail onClick={() => setAppState((prevState: any) => {
                                    const images = [...prevState.images!!]
                                    images.splice(idx, 1)
                                    return {
                                        ...prevState,
                                        images
                                    }
                                })}>
                                    <img src={image.dataUrl} alt={"unknown captured image"}/>
                                </IonThumbnail>
                            </IonCol>
                        ))}

                        {appState.images && appState.images?.length < 3 && (
                            <IonCol>
                                <IonThumbnail style={{border: "1px solid black", alignItems: "center"}}
                                              onClick={addImage}>
                                    <IonIcon icon={addCircleOutline} size="large"/>
                                </IonThumbnail>
                            </IonCol>
                        )}
                    </IonRow>
                    <IonButton
                        disabled={disabled}
                        style={{position: "absolute", left: '20px', right: '20px', bottom: '20px'}}
                        onClick={() => push('/assessment', 'root')}
                               color={"primary"} expand="block" size="large">Submit</IonButton>
                </IonGrid>
            </div>
        </IonContent>
    </IonPage>
};

export default Splash;
