import {
    IonActionSheet,
    IonAvatar,
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonLabel, IonLoading,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton, IonSpinner, useIonRouter
} from '@ionic/react';
import './Assets.css';
import React, {Suspense, useState} from "react";
import AssetList from "./AssetList";
import {useAuth} from "../../components/Auth/AuthProvider";


const Assets: React.FC = () => {
    const {push} = useIonRouter()
    const {signOut} = useAuth()
    const [isActionOpen, setIsActionOpen] = useState(false)
    const [segment, setSegment] = useState<'assets' | 'investments' | 'sell'>("assets")


    return (
    <IonPage>
        <IonHeader>
            <div style={{
                position: 'absolute',
                left: '10px',
                top: '10px'
            }}><IonAvatar onClick={() => setIsActionOpen(true)}>
                <img alt="Silhouette of a person's head" src="/avatar.svg"/>
            </IonAvatar></div>

            <div style={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                top: "20px",
                fontSize: '1.75rem',
            }}><strong>Overview</strong></div>
            <div style={{
                position: 'absolute',
                right: '10px',
                top: '5px',
            }}><IonButton style={{height: '48px'}} expand={"block"} fill={'outline'}
                          onClick={() => push('/splash')}>Sell</IonButton></div>
        </IonHeader>
        <IonContent fullscreen>
            <div style={{height: '65px'}}></div>
            {/* @ts-ignore */}
            <IonSegment value={segment} onIonChange={(e) => setSegment(`${e.target.value}`)}>
                <IonSegmentButton value="assets">
                    <IonLabel>Assets</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="investments">
                  <IonLabel>Invested</IonLabel>
              </IonSegmentButton>
                <IonSegmentButton value="sell">
                    <IonLabel>Sold</IonLabel>
                </IonSegmentButton>
          </IonSegment>
          <Suspense fallback={ <IonSpinner name="dots"/>}>
            <AssetList segment={segment}/>
          </Suspense>

            <IonActionSheet
                isOpen={isActionOpen}
                header="Actions"
                onDidDismiss={(action) => {
                    if (action.detail?.data?.action === 'logout') {
                        signOut?.()
                    }
                    setIsActionOpen(false)
                }}
                buttons={[
                    {
                        text: 'Logout',
                        role: 'destructive',
                        data: {
                            action: 'logout',
                        },
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        data: {
                            action: 'cancel',
                        },
                    },
                ]}
            ></IonActionSheet>
      </IonContent>
    </IonPage>
  );
};

export default Assets;
